<template>
  <dashboard-content full-width>
    <v-row v-if="item">
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Log
        </h1>

        <v-row>
          <v-col class="col-12">
            <v-row justify="space-between">
              <v-col class="col-auto">
                <v-btn
                  link
                  to="/dashboard/admin/logs"
                  color="primary"
                >
                  <v-icon class="mr-2">
                    {{ mdiChevronLeft }}
                  </v-icon> Back
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-12">
            <v-card>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="data in items">
                        <tr
                          v-if="data.value"
                          :key="data.name"
                        >
                          <td>{{ data.name }}</td>
                          <td>
                            <v-btn
                              v-if="data.link"
                              link
                              :to="data.link"
                              color="primary"
                            >
                              {{ data.value }}
                            </v-btn>

                            <template v-else>
                              {{ data.value }}
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'

export default {
  components: { DashboardContent },
  data() {
    return {
      mdiChevronLeft,
      id: null,
      item: null
    }
  },
  computed: {
    items() {
      return [
        {
          name: 'ID',
          value: this.item.id
        },
        {
          name: 'Created at',
          value: this.formatDatetimeLocal(this.item.createdAt)
        },
        {
          name: 'Type',
          value: this.item.type
        },
        {
          name: 'Session ID',
          value: this.item.sessionId
        },
        {
          name: 'Function',
          value: this.item.functionName
        },
        {
          name: 'Message',
          value: this.item.message
        },
        {
          name: 'Reminder',
          value: this.reminderId,
          link: '/dashboard/admin/reminders/' + this.reminderId
        },
        {
          name: 'Notification',
          value: this.notificationId,
          link: '/dashboard/admin/notifications/' + this.notificationId
        }
      ]
    },
    reminderId() {
      if (this.item.schedule) {
        return this.item.schedule.id
      }

      return null
    },
    notificationId() {
      if (this.item.notification) {
        return this.item.notification.id
      }

      return null
    }
  },
  mounted() {
    this.id = this.$route.params.id

    this.$bind(
      'item',
      this.$firebase
        .firestore()
        .collection('logs')
        .doc(this.id)
    )
  }
}
</script>
